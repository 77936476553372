
.tile-wrapper {
    position: relative;
    margin: 3px;
  }
  .tile-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .tile {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    clip-path: polygon(20.5% 0%, 50% 0%, 50% 50%, 3% 26%); 
    
  }
  .tile .left-wing {
    clip-path: polygon(20.5% 0%, 50% 0%, 50% 50%); 
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .tile .right-wing {
    clip-path: polygon(79.5% 0%, 50% 0%, 50% 50%);
    width: 100%;
    height: 100%;
    position: absolute;
    transform: rotate(-60deg);
  }
  .tile .right-wing, .tile .left-wing{
    margin: -2% 0 0 -1%;
  }
  .tile-container .ball-middle {
    position: absolute;
    top: 51%;
    left: 51%;
    width: 15%;
    height: 15%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
  }
  .ball-minor {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
  }
  .mozaic-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 104%;
    height: 100vh;
    margin-left:-2%;
    overflow: hidden;
  }
  .mozaic-column {
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-left: -15px;
    
  }
  .mozaic-column:nth-of-type(even){
    margin-top: -53px;
  }
  
  .tile-menu-container {
    position: absolute;
    top: 10%;
    right: 10%;
    width: 300px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 10px 10px 33px -7px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 33px -7px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 33px -7px rgba(0,0,0,0.75);
    z-index: 1;
  }
  .tile-menu-forehead {
    height: 30px;
    width: 100%;
    background-color: #333;
    border-radius: 8px 8px 0 0;
  }
  .tile-menu-content {
    width: 100%;
    text-align: center;
    padding: 10px 5px 10px 5px;
    z-index: 5;
  }
  .tile-menu-container h1 {
    font-size: 1rem;
    font-family: coolFont;
    color: #333;
  }
  
  .tile-menu-row {
    display: flex;
    margin: 5px;
    padding: 5px;
    flex-direction: row;
    justify-content: center;
    border-radius: 1px  solid black;
    vertical-align: middle;
  }
  .tile-menu-row p {
    position: relative;
    font-size: 1rem;
    padding: 0;
    margin: 0 10px 0 0;
    vertical-align: middle;
  }
  .tile-menu-color-preview {
    position: relative;
    border: 1px solid #333;
    width: 60px;
    height: 40px;
    border-radius: 8px;
  }
  .tile-menu-color-picker-conatainer
  {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #eee;
    padding: 25px 0;
    top: 40px;
    left: 0;
    opacity: 0;
    margin-left: 0;
    width: 300px;
    text-align: center;
    align-items: center;
    border-radius: 8px 0 0 8px;
    transition: all 0.3s ease-in-out, opacity 1s ease-in-out, opacity 0.3s ease-out;
    -moz-transition: all 0.3s ease-in-out, opacity 1s ease-in, opacity 0.3s ease-out;
    -webkit-transition: all 0.3s ease-in-out, opacity 1s ease-in, opacity 0.3s ease-out;
    -o-transition: all 0.3s ease-in-out, opacity 1s ease-in, opacity 0.3s ease-out;
    z-index: -2;
  }
  .tile-menu-color-picker-conatainer button {
    margin: 15px 0 0 0 ;
    padding: 5px;
    border-color: transparent;
    border-radius: 8px;
    background-color: transparent;
    color: #333;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
  }
  .tile-menu-color-picker-conatainer button:hover {
    transform: scale(1.1);
  }
  .color-picker {
    transition: all 2s ease-in-out;
    -moz-transition: all 2s ease-in-out;
    -webkit-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
    z-index: 1;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }