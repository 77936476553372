.cv-section,
.doc-section {
  padding: 0;
  max-width: 100vw;
  margin: var(--navbar-height) auto 0 auto;
}
.pdf-view {
  position: relative;
  height: calc(100vh - var(--navbar-height));
  width: 100vw;
}
